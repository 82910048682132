import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import BarGroupedLegend from "../../components/parts/chart/bar-grouped-legend"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s08_3_1_1 : false,
      s08_4_1_2 : false
    }

    this.searchInit = {
      filter1Init: 4,
      filter2Init: 2,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          <div className={dashboardStyles.chartArea}>
              <div className={dashboardStyles.chartAreaItem}>
                <BarGroupedLegend id="syntheticFuelDemand" chartData="syntheticFuelDemand" width="100%" height="420px" />
              </div>
              
              <div className={dashboardStyles.chartAreaItem}>
                <div className={chartStyles.chartTitle}>
                  <h2>航空業界のカーボンニュートラル達成に向けて必要となるSAFの供給量</h2>
                </div>
                <img src="/images/chart/s08-1-1-2.svg" alt="" width="100%" />
                <div className={chartStyles.chartNote}>
                  <p className={chartStyles.chartSource}>出所）<a href="https://aviationbenefits.org/media/167417/w2050_v2021_27sept_full.pdf" target="_blank" rel="noreferrer noopener">ATAG 「Waypoint 2050 second edition: September 2021」</a>（2021年）<br /><a href="https://www.meti.go.jp/shingikai/energy_environment/saf/pdf/001_04_00.pdf" target="_blank" rel="noreferrer noopener">国土交通省 第1回 SAFの導入促進に向けた官民協議会 説明資料</a></p>
                  <p className={chartStyles.chartExplain}>"Waypoint 2050 second edition: September 2021"において、航空業界の2050年CN達成を前提に、最も積極的にSAFを導入するF3シナリオで必要となるSAF供給量を表示。
                  国土交通省「SAFの導入促進に向けた官民協議会 説明資料」の目測結果を記載している</p>
                  <p><a className={chartStyles.chartDataDownload} href="/csv/bar-line/aircraftSafSupply.csv">データダウンロード</a></p>
                </div>
              </div>
            </div>

            <div className={dashboardStyles.chartArea}>
              <div className={dashboardStyles.chartAreaItem}>
                <div className={chartStyles.chartTitle}>
                  <h2>NZEシナリオにおける低排出燃料の割合</h2>
                </div>
                <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_3_1_1"}>
                  <img src={'../../images/chart/s08-3-1-1.png'} alt="NZEシナリオにおける低排出燃料の割合" width={559} />
                </button>
                <ImageBox
                  open={this.state.s08_3_1_1}
                  close={this.closeModal}
                  name={"s08_3_1_1"}
                  src={'/images/chart/s08-3-1-1.png'}
                  alt="NZEシナリオにおける低排出燃料の割合"
                  width={1200}
                  height={775} />
                <div className={chartStyles.chartNote}>
                  <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/net-zero-by-2050" target="_blank" rel="noreferrer noopener">IEA（2021） Net Zero by 2050</a></p>
                  <p className={styles.chartExplain}>Liquid biofuels in transport : 液体バイオ燃料が輸送部門のエネルギー需要量に占める割合<br />
                  Low-carbon gases in gas grid : バイオメタン、水素、合成メタンが、ビル、工業、輸送、発電向けの天然ガス供給網の中で占める割合<br />
                  Hydrogen-based fuels in TFC : 水素、アンモニア、合成燃料等の水素系燃料が、ビル、工業、輸送の最終エネルギー消費量に占める割合</p>
                </div>
              </div>
              
              <div className={dashboardStyles.chartAreaItem}>
                <div className={chartStyles.chartTitle}>
                  <h2>欧州におけるLPG総需要量とグリーンLPG供給量（2050年）</h2>
                </div>
                <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_4_1_2"}>
                  <img src={'../../images/chart/s08-4-1-2.png'} alt="欧州におけるLPG総需要量とグリーンLPG供給量（2050年）" width={559} />
                </button>
                <ImageBox
                  open={this.state.s08_4_1_2}
                  close={this.closeModal}
                  name={"s08_4_1_2"}
                  src={'/images/chart/s08-4-1-2.png'}
                  alt="欧州におけるLPG総需要量とグリーンLPG供給量（2050年）"
                  width={780}
                  height={549} />
                <div className={chartStyles.chartNote}>
                  <p className={chartStyles.chartSource}>出所）<a href="https://www.liquidgaseurope.eu/publications/biolpg-2050-pathway-report" target="_blank" rel="noreferrer noopener">Liquid Gas Europe（2021）「BioLPG A Renewable Pathway Toward 2050」</a></p>
                  <p className={styles.chartExplain}>2050年時点の欧州におけるLPG総需要量を、HIGH LPG DEMANDとLOW LPG DEMANDの2ケースで推定している</p>
                </div>
              </div>
            </div>
        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`